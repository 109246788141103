import { useContext } from "react";
import AboutUs from "../../components/HomeComponents/AboutUs/AboutUs";
import HeroImage from "../../components/HomeComponents/HeroImage/HeroImage";
import InstagramUpdates from "../../components/HomeComponents/InstagramUpdates/InstagramUpdates";
import ShowcaseItems from "../../components/HomeComponents/ShowcaseItems/ShowcaseItems";
// import Options from "../../components/HomeComponents/Options/Options";
import WebplaceContext from "../../store/webplace-context";

const Home = () => {
  const instagramCtx = useContext(WebplaceContext).homeSocials.instagram_url;
  return (
    <div>
      <HeroImage />
      <AboutUs />
      <ShowcaseItems />
      {/* <Options /> */}
      {instagramCtx.length > 0 && <InstagramUpdates />}
    </div>
  );
};

export default Home;
